import type {
  Api_Interaction,
  Api_Product,
  Enum_LiveStream_Private_Messaging,
  Enum_LiveStream_Provider,
  Enum_LiveStream_Status,
} from '@core/api'

export interface ClientExtension {
  isStreaming?: boolean
}
export interface Api_Video_Poster {
  aspect_ratio: string
  blur_hash: string | null
  format: string
  height: number
  id: string
  key: string
  orientation: string | null
  original?: boolean
  url: string
  video_poster_type: 'static' | 'animated' | string
  width: number
}

export interface Api_Video_VideoSource {
  src: string
  type: string
}

export interface Api_Video_Creator {
  avatar_url: string
  badge: string | null
  block_url?: string
  community_chats_url?: string
  created_videos_url: string
  encoded_id: string
  followships_url: string
  followers_count?: number
  has_unwatched_videos?: boolean
  is_following: boolean
  liked_videos_url?: string
  name: string
  url: string
  username: string
  videos_url: string
  web_share_url: string
}

export interface Api_Video_VideoFile {
  file_url: string
  format: string | null
  has_watermark: boolean
  height: number
  video_file_type: string // 'horizontal' | 'vertical' | 'frameless'
  width: number
  placeholder_url: string | null
  placeholder_blur_hash: string | null
}

export interface Api_Video_Soundtrack {
  artist: string
  encoded_id: string
  id: number
  name: string | null
  thumbnail_url: string | null
  url: string
  videos_url: string
}

export type Api_Video_ActionType =
  | 'apply'
  | 'book'
  | 'buy'
  | 'contact'
  | 'custom'
  | 'donate'
  | 'download'
  | 'enroll_now'
  | 'enter_now'
  | 'get_offer'
  | 'get_quote'
  | 'get_showtimes'
  | 'invest_now'
  | 'join_community'
  | 'learn_more'
  | 'live'
  | 'order'
  | 'order_now'
  | 'play'
  | 'read_more'
  | 'rsvp'
  | 'see_details'
  | 'see_more'
  | 'shop_now'
  | 'shop_recipe'
  | 'view_recipe'
  | 'see_full_recipe'
  | 'book_a_test_drive'
  | 'sign_up'
  | 'subscribe'
  | 'view'
  | 'view_menu'
  | 'talk_to_an_expert'

export interface Api_Video_Subtitles {
  id: string
  key: string
  language?: string
  locale?: string
  url?: string
  video_id: string
  is_cc?: boolean
  filename?: string
}

export interface Api_Video_LiveStreamSubtitles {
  key: string
  locale: string
  is_cc?: boolean
  id?: string
}

export interface Api_Subtitles {
  src: string
  srclang: string
  is_cc: boolean
  id: string
}

export enum Api_Video_Type {
  COLLAB_MIRROR = 'collab_mirror',
  COLLAB_REACT = 'collab_react',
  COLLAB_SPLIT = 'collab_split',
  COLLAB_STITCH = 'collab_stitch',
  FRAMELESS = 'frameless',
  LIVESTREAM = 'live_stream',
  MULTICAM = 'multicam',
  OPEN_AUCTION_AD = 'open_auction_ad',
}

export enum Api_Video_CTA_Type {
  DEFAULT_CTA = 'cta',
  REMIND_ME = 'reminder',
}

export enum Api_Video_AspectRatio {
  HORIZONTAL = '16:9',
  VERTICAL = '9:16',
}

export interface Api_Video {
  access: string
  action_click_url: string
  action_type: Api_Video_ActionType | null
  action_type_translation?: string | null
  action_url: string | null
  ad_id?: string
  auto_apply_coupon_code?: string
  badge: null | 'ad' | 'featured'
  canonical_url: null | string
  caption: string
  comments_count: number
  comments_url: string
  creator: Api_Video_Creator
  cta_button_type?: Api_Video_CTA_Type | null
  deleted_at: string | null
  description?: string | null
  dislikes_url: string
  download_url: string | null // possibly null if video is deleted or restricted by region
  duration: number
  encoded_id: string
  engagements_url: string
  file_url: string | null // possibly null if video is deleted or restricted by region
  flow_interaction?: FlowInteraction | null
  format: string
  hashtags: string[]
  height: number
  id: string
  inserted_at: string
  is_following_creator: unknown | null
  is_liked?: boolean | null
  is_reposted: null
  interactions?: Api_Interaction[]
  likes_count: number
  likes_url: string
  locale: string
  has_access_code?: boolean
  pixels_url: string
  products: Api_Product[]
  published_at: string
  quality: string
  ref_video_encoded_id: string
  ref_video_id: null
  ref_video_url: string | null
  ref_videos_count: number
  report_url: string
  repostable?: boolean
  reposts_count: number
  reposts_url?: string
  shares_count: number
  shares_url: string
  showroom?: {
    id: string
    business_membership_id: string
  }
  soundtrack?: Api_Video_Soundtrack
  started_at?: string
  status: string
  thumbnail_url: string
  url: string
  variant: string | null
  vast_tag: string | null
  video_files: Api_Video_VideoFile[]
  video_posters: Api_Video_Poster[]
  video_sources: Api_Video_VideoSource[]
  video_subtitles: Array<Api_Video_Subtitles>
  video_type: Api_Video_Type | string | null
  views_count: number
  views_url: string
  web_share_url: string
  width: number

  // Livestream related
  live_stream_action_url?: string
  live_stream_announcement?: string
  live_stream_aspect_ratio?: Api_Video_AspectRatio
  live_stream_base_video_id?: string
  live_stream_business_privacy_policy_url?: string
  live_stream_chat_enabled?: boolean
  live_stream_chat_in_replay_enabled?: boolean
  live_stream_chat_moderation_enabled?: boolean
  live_stream_duration?: number
  live_stream_ended_at?: string | null
  live_stream_force_private_messaging_on_replay?: boolean
  live_stream_has_products?: boolean
  live_stream_id?: string
  live_stream_nsfw_words?: null
  live_stream_playback_url?: string
  live_stream_private_messaging?: Enum_LiveStream_Private_Messaging
  live_stream_provider?: Enum_LiveStream_Provider
  live_stream_replay_enabled?: boolean
  live_stream_replay_interactions_enabled?: boolean
  live_stream_replay_messaging_enabled?: boolean
  live_stream_replay_skips_to?: number | null
  live_stream_replay_url?: string | null
  live_stream_scheduled_at?: string
  live_stream_source?: string
  live_stream_subtitles?: Api_Video_LiveStreamSubtitles[]
  live_stream_started_at?: string | null
  live_stream_status?: Enum_LiveStream_Status
  live_stream_test_mode?: boolean
  live_stream_transcription_enabled?: boolean
  live_stream_viewers_count_enabled?: boolean
  live_stream_terms_and_conditions?: string
  live_stream_video_source?:
    | 'latest_live_stream'
    | 'specific_live_stream'
    | null

  clientExtension?: ClientExtension
}

export enum VideoEmailEmbedType {
  ANIMATED = 'animated',
  STATIC = 'static',
}

export enum VideoEmailEmbedSize {
  SMALL = 'small',
  BEST_FIT = 'large',
}

export interface ApiVideoEmailEmbed {
  embed_code: string
}

export interface ApiVideoEmailEmbedParams {
  videoId: string
  embedSize: VideoEmailEmbedSize
  embedType: VideoEmailEmbedType
}

export interface Track_Ad_Setting {
  ad_request_id?: string
  _ad_setting_id?: string | undefined
  _ad_tag_id?: string
  advertisable_type?: string
  advertisable_id?: string
  ad_target_platform: string | undefined
  creative_type?: string
  creative_id?: string
  _placement_id?: string
  channel_id?: string
}

export interface FlowInteraction {
  id: string
  deleted_at: string | null
  inserted_at: string
  updated_at: string | null
  flow_id: string
  flow_start_video_id: string
  prompt_text: string | null
  shows_in: number
  video_id: string
  type: FlowInteractionEnum
  flow_links: FlowLink[]
}
export enum FlowInteractionEnum {
  MULTIPLE_CHOICE = 'multiple_choice',
  CTA = 'cta',
  PRODUCT = 'product',
  QUESTION = 'question',
  POLL = 'poll',
}

export interface FlowLink {
  id: string
  deleted_at: string | null
  flow_interaction_id: string
  metadata: string | null
  target_type: string
  target_url: string | null
  target_video_id: string | null
  text: string | null
}
